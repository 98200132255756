<template>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="301.000000pt" height="128.000000pt" viewBox="0 0 301.000000 128.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M2860 1189 c-305 -152 -681 -279 -827 -279 l-25 0 31 39 c28 35 37
39 115 55 129 26 180 43 206 71 13 14 20 25 16 25 -12 0 -172 -49 -551 -170
-187 -60 -400 -127 -472 -150 l-133 -42 0 -105 0 -105 173 4 c155 3 177 6 224
27 92 41 161 136 175 239 3 20 5 21 21 10 17 -13 16 -18 -18 -107 -19 -52 -34
-96 -32 -98 8 -7 58 40 88 83 16 24 34 44 40 44 6 0 24 -30 40 -67 49 -111 53
-116 102 -154 47 -35 172 -87 184 -76 3 3 -7 9 -21 13 -46 11 -142 80 -166
119 -24 38 -73 185 -65 193 3 3 23 -33 44 -81 44 -93 65 -114 168 -166 66 -33
110 -39 146 -20 27 15 110 89 98 89 -5 0 -47 7 -94 15 -124 22 -207 55 -288
116 -39 30 -75 53 -79 52 -5 -1 -14 4 -21 13 -10 12 -9 20 8 42 18 24 30 28
118 39 136 17 252 52 451 137 173 73 509 258 447 244 -5 0 -51 -23 -103 -49z
m-1230 -419 c-24 -82 -96 -130 -198 -130 -58 0 -62 2 -62 23 0 19 19 30 128
78 70 32 130 57 134 58 4 1 3 -12 -2 -29z"/>
<path d="M1506 1221 c5 -4 78 -52 162 -106 83 -53 152 -101 152 -105 0 -9 -1
-9 324 70 165 40 214 55 201 63 -27 15 -99 2 -224 -38 l-113 -37 -229 81
c-206 72 -301 98 -273 72z"/>
<path d="M205 1135 c125 -74 419 -202 545 -238 89 -26 183 -42 311 -53 l76 -6
-42 -43 c-35 -35 -48 -58 -70 -130 -30 -99 -76 -159 -155 -201 -56 -31 -12
-20 52 12 58 30 116 85 124 122 9 34 58 130 81 158 33 39 107 81 176 99 l67
18 0 78 0 79 53 0 c72 0 121 -16 161 -51 l34 -29 71 17 c48 11 71 22 71 32 0
27 -86 98 -141 117 -44 15 -85 19 -219 19 l-165 0 -3 -121 -3 -121 -42 3 c-23
2 -100 6 -172 8 -91 4 -160 13 -230 30 -165 40 -415 136 -597 227 -114 57
-108 47 17 -26z"/>
<path d="M92 1096 c7 -56 46 -142 87 -188 36 -42 68 -53 276 -99 214 -47 451
-49 572 -4 38 14 37 14 -49 14 -112 1 -160 11 -348 74 -108 35 -214 81 -349
149 l-194 98 5 -44z"/>
<path d="M2715 1041 c-125 -64 -238 -113 -330 -144 -173 -57 -215 -67 -335
-76 l-95 -7 75 -18 c107 -26 320 -24 460 3 165 33 291 68 319 89 50 36 141
255 105 251 -5 0 -94 -44 -199 -98z"/>
<path d="M2004 766 c41 -33 177 -96 237 -111 77 -18 262 -30 312 -21 25 5 51
22 86 58 75 77 69 94 -24 72 -95 -22 -269 -44 -348 -44 -69 0 -214 28 -260 51
-21 10 -21 9 -3 -5z"/>
<path d="M320 753 c0 -11 25 -43 56 -73 l56 -53 146 6 c155 6 222 20 306 61
61 31 139 80 111 71 -152 -50 -300 -58 -492 -24 -65 11 -133 23 -150 26 -28 5
-33 3 -33 -14z"/>
<path d="M990 724 c-99 -80 -216 -126 -358 -140 -51 -6 -60 -9 -46 -17 11 -6
38 -27 61 -49 60 -54 94 -54 199 -2 87 43 116 72 144 143 11 30 30 67 41 83
11 15 19 28 17 28 -2 -1 -28 -21 -58 -46z"/>
<path d="M330 316 l0 -96 55 0 c72 0 105 28 105 89 0 61 -31 91 -102 98 l-58
6 0 -97z m116 53 c8 -7 14 -30 14 -60 0 -55 -15 -69 -74 -69 l-36 0 0 68 c0
38 3 72 8 76 9 10 70 0 88 -15z"/>
<path d="M520 395 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M1550 316 c0 -79 3 -96 15 -96 11 0 15 11 15 40 l0 40 45 0 45 0 0
-40 c0 -22 5 -40 10 -40 6 0 10 38 10 95 0 95 -16 133 -22 53 -3 -43 -3 -43
-45 -46 l-42 -3 -3 42 c-2 23 -9 44 -15 47 -10 3 -13 -20 -13 -92z"/>
<path d="M1914 397 c-3 -8 -4 -47 -2 -87 2 -56 7 -75 20 -84 9 -5 21 -6 25 -2
4 5 2 11 -4 13 -9 3 -13 31 -13 89 0 51 -4 84 -10 84 -6 0 -13 -6 -16 -13z"/>
<path d="M1980 396 c0 -9 7 -16 16 -16 9 0 14 5 12 12 -6 18 -28 21 -28 4z"/>
<path d="M2170 375 c0 -35 0 -36 -34 -30 -34 7 -73 -6 -88 -29 -4 -6 -8 -23
-8 -38 0 -53 83 -78 131 -39 21 17 24 28 24 92 0 49 -4 74 -12 76 -9 3 -13 -7
-13 -32z m-16 -61 c31 -31 13 -74 -32 -74 -55 0 -78 36 -45 72 20 22 56 23 77
2z"/>
<path d="M520 285 c0 -37 4 -65 10 -65 6 0 10 28 10 65 0 37 -4 65 -10 65 -6
0 -10 -28 -10 -65z"/>
<path d="M586 337 c-29 -21 -12 -55 30 -63 49 -9 45 -34 -6 -34 -22 0 -40 -4
-40 -10 0 -17 86 -12 101 6 18 22 0 48 -37 54 -17 3 -30 12 -32 23 -3 14 4 17
32 17 20 0 36 5 36 10 0 15 -63 12 -84 -3z"/>
<path d="M725 325 c-14 -13 -25 -31 -25 -40 0 -44 80 -81 120 -55 17 11 9 13
-33 10 -35 -2 -57 15 -57 46 0 28 33 48 65 40 27 -7 43 9 19 18 -32 12 -66 5
-89 -19z"/>
<path d="M885 338 c-27 -15 -35 -28 -35 -60 0 -33 33 -58 76 -58 74 0 106 83
44 115 -34 18 -58 19 -85 3z m79 -24 c34 -33 12 -74 -39 -74 -52 0 -71 36 -38
72 20 22 56 23 77 2z"/>
<path d="M1020 345 c0 -18 62 -125 73 -125 12 0 80 120 72 128 -8 9 -26 -11
-48 -54 l-23 -45 -28 50 c-15 28 -31 51 -37 51 -5 0 -9 -2 -9 -5z"/>
<path d="M1207 330 c-53 -42 -20 -114 49 -108 19 2 34 7 34 13 0 5 -13 8 -29
7 -16 -2 -33 3 -41 12 -11 14 -5 16 54 16 72 0 81 9 49 49 -27 33 -82 38 -116
11z m84 -10 c33 -18 21 -30 -31 -30 -51 0 -64 11 -32 29 22 13 39 13 63 1z"/>
<path d="M1415 338 c-33 -17 -45 -40 -45 -80 0 -27 4 -38 15 -38 11 0 15 12
15 46 0 42 3 47 30 57 30 11 41 27 18 27 -7 0 -22 -6 -33 -12z"/>
<path d="M1763 340 c-22 -9 -43 -39 -43 -60 0 -6 11 -22 25 -35 45 -46 135
-24 135 33 0 33 -8 45 -37 61 -25 13 -49 13 -80 1z m79 -27 c22 -20 23 -37 2
-57 -18 -19 -65 -21 -82 -4 -46 46 31 105 80 61z"/>
<path d="M1980 286 c0 -53 3 -66 15 -66 13 0 15 11 13 62 -2 38 -8 63 -15 66
-10 3 -13 -14 -13 -62z"/>
<path d="M2265 339 c-27 -17 -35 -29 -35 -61 0 -40 33 -58 103 -56 l57 2 0 37
c0 20 -8 46 -17 58 -21 26 -80 36 -108 20z m76 -19 c21 -12 25 -50 7 -68 -17
-17 -64 -15 -82 4 -20 20 -20 33 1 56 18 20 45 23 74 8z"/>
<path d="M2420 302 c0 -58 20 -82 68 -82 36 0 41 -14 12 -32 -23 -15 -26 -28
-7 -28 33 0 47 35 47 113 0 87 -17 106 -22 25 -3 -48 -6 -53 -27 -56 -32 -5
-51 21 -51 70 0 21 -4 38 -10 38 -5 0 -10 -22 -10 -48z"/>
<path d="M2596 337 c-29 -21 -16 -51 27 -61 51 -13 49 -36 -3 -36 -22 0 -40
-4 -40 -10 0 -5 20 -10 45 -10 32 0 47 5 54 18 13 24 -4 52 -32 52 -29 0 -51
15 -43 29 4 6 23 11 42 11 19 0 34 5 34 10 0 15 -63 12 -84 -3z"/>
<path d="M2432 93 l-2 -43 38 2 c36 1 36 1 5 5 -28 4 -32 8 -36 41 l-4 37 -1
-42z"/>
<path d="M2507 123 c17 -3 23 -13 26 -41 l4 -37 1 37 c1 25 7 38 20 41 9 3 -3
5 -28 4 -25 0 -35 -2 -23 -4z"/>
<path d="M2590 90 c0 -36 3 -40 24 -40 41 0 56 11 56 40 0 29 -15 40 -56 40
-21 0 -24 -4 -24 -40z m65 0 c0 -20 -6 -26 -27 -28 -25 -3 -28 0 -28 28 0 28
3 31 28 28 21 -2 27 -8 27 -28z"/>
</g>
</svg>

</template>
